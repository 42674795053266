@import "~@/assets/scss/global.scss";












































































































































































































































































































::v-deep {
    .span_wname {
        color: #9e9e9e;
        font-size: 12px;
    }

    .el-aside {
        .el-header {
            .date-range {
                width: 200px;

                .el-range-input {
                    width: 70px;
                }
            }

            .el-form-item__label {
                padding-right: 0;
            }
        }
    }

    .el-main {
        .el-header {
            .span-spec-name {
                font-weight: bold;
                font-size: 20px;
            }

            .span-spec-count {
                margin-left: 20px;
                font-size: 16px;
            }
        }

        .el-table {
            .size-tag {
                height: 36px;
                line-height: 33px;
                margin: 6px 5px 3px 0px;
                float: left;

                .el-input--small .el-input__inner {
                    height: 22px;
                    line-height: 22px;
                    padding: 0px;
                    text-align: center;
                }
            }

            .tag-item:hover .icon-delete {
                display: block;
            }

            .span-delete {
                color: red;
                font-size: 12px;
                cursor: pointer;
                position: absolute;
                margin-top: 15px;
            }

            .span-delete:hover {
                text-decoration: underline;
            }

            .icon-delete {
                background-color: red;
                border-radius: 10px;
                color: #fff;
                opacity: 0.8;
                float: left;
                cursor: pointer;
                display: none;
                margin-left: -15px;
            }
        }
    }
}
